/* eslint-disable no-restricted-globals */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { NavigateFunction } from 'react-router';
import httpClient from '../../../app/client/httpClient';
import { RootState } from '../../../app/store';
import {
  AccountManagerRoutes,
  AccountRoutes,
  AuthRoutes,
  ListingsRoutes,
} from '../../../app/types/navigation';
import { actions, IAuthResponse } from './slice';

interface IFetchAuthRoleParams {
  navigate?: NavigateFunction;
}

export const fetchAuthRole = createAsyncThunk(
  'fetchAuthRole',
  async ({ navigate }: IFetchAuthRoleParams, { dispatch, getState }) => {
    try {
      const { data } = await httpClient.get<IAuthResponse>('api/me');
      dispatch(actions.setAuth(data));
      const state = getState() as RootState;
      if (state.auth.auth.role === 'accountManager' && navigate) {
        navigate(`/${AccountManagerRoutes.ACCOUNT_MANAGER}/${AccountManagerRoutes.COMPANIES}`);
      } else if (navigate) {
        navigate('/' + AccountRoutes.COMPANY_CONTRACTS);
      }
    } catch (e: unknown) {
      const errors = e as AxiosError;
      if (
        !location.pathname.includes('/signup') &&
        !location.pathname.includes('/login') &&
        !location.pathname &&
        navigate
      ) {
        navigate(AuthRoutes.SIGNUP);
      }
      console.log(errors);
    }
  },
);

export const fetchStartedAuthRole = createAsyncThunk(
  'fetchStartedAuthRole',
  async ({ navigate }: IFetchAuthRoleParams, { dispatch }) => {
    try {
      if (!location.pathname.includes('/login') && !location.pathname.includes('/terms')) {
        const { data } = await httpClient.get<IAuthResponse>('api/me');

        dispatch(actions.setAuth(data));
      }
    } catch (e: unknown) {
      const errors = e as AxiosError;
      if (
        !location.pathname.includes('/signup') &&
        !location.pathname.includes('/login') &&
        !location.pathname &&
        navigate
      ) {
        // navigate(AuthRoutes.SIGNUP);
      }
      console.log(errors);
    }
  },
);
