import { ReactNode } from 'react';
import { AccountManager } from '../../accountManager';
import { Companies } from '../../accountManager/companies';
import { AccountManagerRoutes, AccountRoutes, AuthRoutes } from '../types/navigation';
import { Login } from '../../auth/login';
import SignUp from '../../auth/signup';
import { CompanyEdit } from '../../account/editCompany';
import { Landing } from '../../landing';
import { Contracts } from '../../accountManager/contracts';
import { Contracts as CompanyContracts } from '../../account/contracts';
import { Contract } from 'src/account/contract';
import { Contract as BackofficeContract } from 'src/accountManager/contract';
import { HedgeRequests } from 'src/hedgeAdmin/hedging/hedgeRequests';
import { HedgeAtWork } from 'src/hedgeAdmin/hedging/hedgeAtWork';
import { Unhedged } from 'src/hedgeAdmin/hedging/unhedged';
import { HedgeHistory } from 'src/hedgeAdmin/hedging/hedgeHistory';
import { UserHedgeHistory } from 'src/account/hedgeHistory';
import { Terms } from 'src/auth/terms';

export enum RolesEnum {
  ACCOUNT_MANAGER = 'accountManager',
  TRADER = 'trader',
}

export interface IRouteObject {
  path: string;
  access: RolesEnum | null;
  component: ReactNode;
  children?: IRouteObject[];
  isIndex?: boolean;
}

const COMMON_ROUTES = [
  { access: null, component: <Landing />, path: '/' },
  { access: null, component: <Login />, path: AuthRoutes.LOGIN },
  { access: null, component: <Terms />, path: AuthRoutes.TERMS },
];

const ACCOUNT_MANAGER_ROUTES = [
  {
    access: RolesEnum.ACCOUNT_MANAGER,
    component: <AccountManager />,
    path: AccountManagerRoutes.ACCOUNT_MANAGER,
    children: [
      {
        access: RolesEnum.ACCOUNT_MANAGER,
        component: <Companies />,
        path: AccountManagerRoutes.COMPANIES,
      },
      {
        access: RolesEnum.ACCOUNT_MANAGER,
        component: <Contracts />,
        path: AccountManagerRoutes.CONTRACTS_DASHBOARD,
      },
      {
        access: RolesEnum.ACCOUNT_MANAGER,
        component: <BackofficeContract />,
        path: AccountManagerRoutes.CONTRACTS_DASHBOARD + '/:contractId',
      },
      {
        access: RolesEnum.ACCOUNT_MANAGER,
        component: <HedgeRequests />,
        path: AccountManagerRoutes.HEDGE_REQUESTS,
      },
      {
        access: RolesEnum.ACCOUNT_MANAGER,
        component: <HedgeAtWork />,
        path: AccountManagerRoutes.HEDGE_AT_WORK,
      },
      {
        access: RolesEnum.ACCOUNT_MANAGER,
        component: <Unhedged />,
        path: AccountManagerRoutes.UNHEDGED,
      },
      {
        access: RolesEnum.ACCOUNT_MANAGER,
        component: <HedgeHistory />,
        path: AccountManagerRoutes.HEDGE_HISTORY,
      },
    ],
  },
];

const TRADER_ROUTES = [
  {
    access: RolesEnum.TRADER,
    component: <CompanyContracts />,
    path: AccountRoutes.COMPANY_CONTRACTS,
  },
  {
    access: RolesEnum.TRADER,
    component: <Contract />,
    path: AccountRoutes.COMPANY_CONTRACTS + '/:contractId',
  },
  {
    access: RolesEnum.TRADER,
    component: <UserHedgeHistory />,
    path: AccountRoutes.COMPANY_HEDGING,
  },
  { access: RolesEnum.TRADER, component: <CompanyEdit />, path: AccountRoutes.COMPANY },
];

export const ROUTES: IRouteObject[] = [
  ...COMMON_ROUTES,
  ...TRADER_ROUTES,
  ...ACCOUNT_MANAGER_ROUTES,
];
